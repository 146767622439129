import React, {useEffect} from 'react';
import bg from './bg.png'
import './App.css';

function App() {
    return (
        // <div className="App">
        //   <header className="App-header">
        //     <img src={logo} className="App-logo" alt="logo" />
        //     <p>
        //       Edit <code>src/App.tsx</code> and save to reload.
        //     </p>
        //     <a
        //       className="App-link"
        //       href="https://reactjs.org"
        //       target="_blank"
        //       rel="noopener noreferrer"
        //     >
        //       Learn React
        //     </a>
        //   </header>
        // </div>
        <div onClick={downloadClick} className="App">
            <div>
                {/*<img className={"App-bg"} draggable="false" src={bg} alt=""/>*/}
            </div>
        </div>
    );
}

const downloadClick = () => {
    console.log(window.location.search)
    const paramsString = window.location.search.split('?')[1];
    const paramMap = new Map();
    if (paramsString) {
        const params = paramsString.split("&");
        params.map(item => {
            const param = item.split('=');
            paramMap.set(param[0], param[1]);
        })
    }
    console.log(paramMap)

    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(paramMap.get("inviteCode"))
            .then(() => {
                console.log('Text successfully copied to clipboard');
                const link = document.createElement('a');
                link.href = paramMap.get("downloadUrl")
                link.download = 'download.apk'; // 指定下载的文件名
                link.click(); // 自动点击链接以触发下载
            })
            .catch(err => {
                try{
                    const textArea = document.createElement('textarea')
                    textArea.value = paramMap.get("inviteCode")
                    // 使text area不在viewport，同时设置不可见
                    document.body.appendChild(textArea)
                    textArea.focus()
                    textArea.select()
                    return new Promise((resolve, reject) => {
                        // 执行复制命令并移除文本框
                        document.execCommand('copy') ? resolve(null) : reject(new Error('出错了'))
                        textArea.remove()
                    }).then(
                        () => {
                            // alert("复制成功");
                            const link = document.createElement('a');
                            link.href = paramMap.get("downloadUrl")
                            link.download = 'download.apk'; // 指定下载的文件名
                            link.click(); // 自动点击链接以触发下载
                        },
                        (reason) => {
                            throw reason;
                        })
                }catch (e){
                    alert("复制失败");
                    const link = document.createElement('a');
                    link.href = paramMap.get("downloadUrl")
                    link.download = 'download.apk'; // 指定下载的文件名
                    link.click(); // 自动点击链接以触发下载
                };
            });
    }

}

export default App;
